'use client';

// imports
import { useState, useCallback } from "react";
import useDidMount from 'beautiful-react-hooks/useDidMount';

// lib files
import { VideoClass } from '@/lib/types/api/video';

// components
import SponsorshipUnit from "@/components/SponsorshipUnit/SponsorshipUnit";
import VideoDetailThumbnail from "@/components/VideoDetailThumbnail/VideoDetailThumbnail";
import styles from './SpecialsTab.module.scss';
interface SpecialsTabProps {
  showSlug: string;
  specials: VideoClass[];
  adUnit: string;
}
async function fetchAllSpecials(showSlug: string) {
  const url = `/api/show/${showSlug}/specials/`;
  const response = await fetch(url);
  return await response.json();
}
const SpecialsTab = (props: SpecialsTabProps) => {
  const {
    adUnit,
    specials,
    showSlug
  } = props;
  const [allSpecials, setAllSpecials] = useState<VideoClass[]>(specials);
  const getAllEpisodesData = useCallback(async () => {
    try {
      const response = await fetchAllSpecials(showSlug);
      setAllSpecials(response);
    } catch (error) {
      console.error({
        error
      });
      // @TODO make an error state?
    }
  }, [showSlug]);
  useDidMount(getAllEpisodesData);
  return <div className={styles.specials_tab} data-sentry-component="SpecialsTab" data-sentry-source-file="SpecialsTab.tsx">
    <div className={styles.specials_list}>
    {allSpecials.map((special, index: number) => {
        return <VideoDetailThumbnail video={special} key={index} />;
      })}
    </div>

    <SponsorshipUnit adUnit={adUnit} size={[[300, 250], [300, 600]]} id="medium-rectangle-half-page-specials-tab" data-sentry-element="SponsorshipUnit" data-sentry-source-file="SpecialsTab.tsx" />
    </div>;
};
export default SpecialsTab;