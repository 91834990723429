import ITSImage from '@/components/ITSImage/ITSImage';
import { PhotoGalleryContent } from '@/lib/types/api/show-data';
import styles from './GalleryPhoto.module.scss';
interface GalleryPhotoProps {
  photo: PhotoGalleryContent;
  className?: string;
}
const GalleryPhoto: React.FC<GalleryPhotoProps> = props => {
  const {
    photo: {
      image_url,
      alt_text
    },
    className
  } = props;
  let classNames = styles.photo_gallery__photo;
  if (className) {
    classNames += ` ${className}`;
  }
  return <div className={styles.photo_gallery__photo_wrapper} data-sentry-component="GalleryPhoto" data-sentry-source-file="GalleryPhoto.tsx">
      <ITSImage width={0} src={image_url} srcSetSizes={[[320, 180], [480, 270], [768, 432], [1024, 576], [1216, 684]]} alt={alt_text} loading="lazy" className={styles.photo_gallery__photo} data-sentry-element="ITSImage" data-sentry-source-file="GalleryPhoto.tsx" />
    </div>;
};
export default GalleryPhoto;