import styles from './SponsorshipRow.module.scss';
import SponsorshipText from '@/components/SponsorshipText/SponsorshipText';
import SponsorshipLogos from '@/components/SponsorshipLogos/SponsorshipLogos';
export interface Sponsor {
  url: string;
  image: string;
  title: string;
}
interface SponsorshipRowProps {
  id?: string;
  className?: string;
  title: string;
  source: {
    local_content_stations?: string[];
    funder_message?: string;
    sponsor_logos?: Sponsor[];
  };
  sponsorInfoLink?: string;
  sponsorshipLogosPagePosition: 'top' | 'bottom';
  // the absence of one of these props means that it should be visible at all breakpoints
  sponsorshipRowVisbility?: 'mobile' | 'desktop';
  sponsorshipTextVisibility?: 'mobile' | 'desktop';
}
const SponsorshipRow: React.FC<SponsorshipRowProps> = props => {
  const {
    id,
    className,
    title,
    source,
    sponsorInfoLink,
    sponsorshipRowVisbility,
    sponsorshipLogosPagePosition,
    sponsorshipTextVisibility
  } = props;
  let responsiveClass = '';
  if (sponsorshipRowVisbility === 'mobile') {
    responsiveClass = ' visible_below_sm';
  } else if (sponsorshipRowVisbility === 'desktop') {
    responsiveClass = ' visible_above_sm';
  }
  const shouldRender = source.funder_message != '' || source.sponsor_logos && source.sponsor_logos?.length > 0;
  if (shouldRender) {
    return <div className={`${styles.sponsorship_row}${className ? ` ${className}` : ''}${responsiveClass}`} id={id}>
        <SponsorshipText title={title} source={source} sponsorInfoLink={sponsorInfoLink} className={styles.sponsorship_row_text} sponsorshipTextVisibility={sponsorshipTextVisibility} />
        <SponsorshipLogos sponsorshipLogos={source.sponsor_logos} sponsorshipLogosPagePosition={sponsorshipLogosPagePosition} />
      </div>;
  } else {
    return null;
  }
};
export default SponsorshipRow;